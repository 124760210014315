import classes from './CreatePostBox.header.module.scss';

import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import Flex from '@lobox/uikit/Flex/index';
import Typography from '@lobox/uikit/Typography';
import { useGlobalDispatch, useTranslation } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import Link from '@lobox/uikit/Link';

const CreatePostBoxHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const { getAppObjectPropValue, isBusinessApp } = useGetAppObject();
  const dispatch = useGlobalDispatch();

  const openModal = () => {
    // event.trigger(eventKeys.openCreatePostModal, null);
    dispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: true,
        currentTab: 'main',
      },
    });
  };

  const username = getAppObjectPropValue({
    userKey: 'username',
    pageKey: 'username',
  });

  return (
    <Flex className={classes.header} onClick={openModal}>
      <Link to={`/${username}`}>
        <Avatar
          isCompany={isBusinessApp}
          className={classes.avatar}
          imgSrc={getAppObjectPropValue({
            userKey: 'croppedImageUrl',
            pageKey: 'croppedImageUrl',
          })}
          showStatus={false}
          size="smd"
        />
      </Link>
      <Typography className={classes.headerText}>
        {t('think_outside_the_box')}
      </Typography>
    </Flex>
  );
};

export default CreatePostBoxHeader;
