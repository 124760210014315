import classes from './MainContent.component.module.scss';

import React, { useEffect, useRef } from 'react';
import {
  CREATE_POST_BOX,
  event,
  QueryKeys,
  routeNames,
  useHistory,
  useTranslation,
  useUpdateInfinityData,
} from '@lobox/utils';
import FeedCard from '@shared/components/Organism/FeedCard';
import FeedSkeleton from '@shared/components/Organism/FeedCard/FeedCard.skeleton';
import EmptyState from '@shared/components/Organism/FeedCard/FeedCard.empty';
import useGetFeedList from '@shared/hooks/api-hook/useGetFeedList';
import eventKeys from '@shared/constants/event-keys';
import FeedCardNextPageSkeleton from '@shared/components/Organism/FeedCard/FeedCardNextPage.skeleton';
import CreatePostProgressBar from '@shared/components/Organism/CreatePostProgressBar';
import Flex from '@lobox/uikit/Flex/index';
import CreatePostBox from '@shared/components/Organism/CreatePostBox';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import InfiniteList from '@shared/uikit/InfiniteList/InfiniteList';
import type { IFeedElement, PaginateResponse } from '@lobox/utils';

const PostsList = (): JSX.Element => {
  const { t } = useTranslation();
  const reftechApiRef = useRef<Boolean>(false);
  const parentRef = useRef(null);
  const listRef = useRef<PaginateResponse<IFeedElement>[]>([]);
  const history = useHistory();
  const KEY = React.useMemo(() => [QueryKeys.homeFeedList], []);

  const { get, add, remove, replace, refetch } =
    useUpdateInfinityData<any>(KEY);

  const { getAppObjectPropValue } = useGetAppObject();

  const authEntityTitle = getAppObjectPropValue({
    userKey: 'fullName',
    pageKey: 'title',
  });

  const handleNavigate = () => {
    history.push(routeNames.peopleDiscover);
  };

  const onError = (e: any) => {
    if (
      e?.response?.data?.error === 'ZeroPageIsNotCalledException' &&
      !reftechApiRef?.current
    ) {
      reftechApiRef.current = true;
      refetch();
    }
  };

  const {
    data: list,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
  } = useGetFeedList({ onError });
  listRef.current = list;
  const isLoading = status === 'loading';

  useEffect(() => {
    event.on(eventKeys.submitPost, (props: any) => {
      const { data } = props.detail;
      add(
        {
          post: data,
          id: data.id,
          activities: [],
          comments: null,
          postId: data.id,
        },
        {
          page: 'first',
          addToFirstOfList: true,
        }
      );
    });
    event.on(eventKeys.updatePost, (props: any) => {
      const { data } = props.detail;
      const feed = get(data.id);
      if (feed) replace({ ...feed, post: data });
    });
    return () => {
      event.off(eventKeys.submitPost);
      event.off(eventKeys.updatePost);
    };
  }, []); // eslint-disable-line

  const handleUpdatePost = React.useCallback(
    (feed: IFeedElement) => {
      replace(feed);
    },
    [replace]
  );

  const handleDeletePost = React.useCallback(
    (feed: IFeedElement) => {
      remove(feed.id);
    },
    [remove]
  );

  const handleAddPost = React.useCallback(
    (feed: IFeedElement) => {
      add(
        { ...feed, id: Math.random, activities: [] },
        {
          page: 'first',
          addToFirstOfList: true,
        }
      );
    },
    [add]
  );

  useEffect(() => {
    if (parentRef.current)
      event.on(eventKeys.scrollToTopFeedList, () => {
        document.getElementById('CREATE_POST_BOX').scrollIntoView();
      });
    return () => event.off(eventKeys.scrollToTopFeedList);
  }, []);

  const initialLoadingSkeleton = React.useMemo(
    () => (
      <>
        <FeedSkeleton />
        <FeedSkeleton />
      </>
    ),
    []
  );

  const topListItem = React.useMemo(
    () => (
      <Flex id={CREATE_POST_BOX}>
        <CreatePostBox />
        <CreatePostProgressBar />
      </Flex>
    ),
    []
  );

  const emptyPlaceholder = React.useMemo(
    () => (
      <EmptyState
        className={classes.feedEmptyClasses}
        caption={authEntityTitle}
        description={t('es_home_desc')}
        action={{
          title: t('find_people_follow'),
          onClick: handleNavigate,
        }}
      />
    ),
    []
  );

  const nextPageLoadingSkeleton = React.useMemo(
    () => <FeedCardNextPageSkeleton className={classes.spinner} />,
    []
  );

  const ListItem = React.useCallback(
    ({ index }: { index: number }) => {
      const feedElement = listRef.current[index];
      return (
        <FeedCard
          feedElement={{
            ...feedElement,
            // @ts-ignore
            isFollowingFromOtherPlaces: true,
          }}
          visibleNetworkInfoOnHeader
          onAddPost={handleAddPost}
          onReplacePost={handleUpdatePost}
          onDeletePost={handleDeletePost}
        />
      );
    },
    [handleAddPost, handleUpdatePost, handleDeletePost]
  );

  const getItemKey = React.useCallback(
    (index: number) => listRef.current[index]?.id || index.toString(),
    []
  );

  return (
    <InfiniteList
      initialLoadingSkeleton={initialLoadingSkeleton}
      topListItem={topListItem}
      emptyPlaceholder={emptyPlaceholder}
      nextPageLoadingSkeleton={nextPageLoadingSkeleton}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      ListItem={ListItem}
      isLoadingInitially={isLoading}
      itemsCount={listRef.current.length}
      overscan={5}
      listItemMaxEstimatedHeight={1000}
      fetchNextPage={fetchNextPage}
      getItemKey={getItemKey}
    />
  );
};

export default PostsList;
