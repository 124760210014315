'use client';

import classes from './page.module.scss';

import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useSignUpRedirections } from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import Flex from '@lobox/uikit/Flex/index';
import PermissionDeniedAlert from '@shared/components/Organism/PermissionDeniedAlert';
import PermissionsGate from '@shared/components/molecules/PermissionsGate';
import { SCOPES } from '@shared/constants/userRoles.scopes';
import { useParams } from '@lobox/utils';
import { HomeProvider as Provider } from './partials/context/home.provider';
import MainContent from './partials/sections/MainContent';

const Hashtag = dynamic(() => import('./partials/sections/Hashtag'));
const UserRightSideBar = dynamic(
  () => import(`./partials/sections/UserRightSideBar`)
);
const BusinessRightSideBar = dynamic(
  () => import(`./partials/sections/BusinessRightSideBar`)
);

const HomePage = (): JSX.Element => {
  const { hashtag } = useParams<{ hashtag?: string }>();
  const decodedHashtag = hashtag ? decodeURIComponent(hashtag) : undefined;
  const { getRedirectUrl, redirect } = useSignUpRedirections();

  useEffect(() => {
    if (getRedirectUrl()) {
      redirect();
    }
  }, []);

  return (
    <PermissionsGate
      scopes={[SCOPES.canSeeHomeScreen]}
      renderFallback={<PermissionDeniedAlert />}
    >
      <Provider>
        <Hashtag hashtag={decodedHashtag} />
        <Flex flexDir="row" className={classes.homeContainer}>
          <Flex className={classes.postListContainer}>
            <MainContent />
          </Flex>
          <Flex className={classes.postSidebar}>
            {isBusinessApp ? <BusinessRightSideBar /> : <UserRightSideBar />}
          </Flex>
        </Flex>
      </Provider>
    </PermissionsGate>
  );
};

export default HomePage;
