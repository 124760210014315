import { getFeedList, QueryKeys, useInfiniteQuery } from '@lobox/utils';

type UseGetFeedList = {
  enabled?: boolean;
  onError: (e: any) => void;
};

const useGetFeedList = ({ enabled, onError }: UseGetFeedList) => {
  return useInfiniteQuery(
    [QueryKeys.homeFeedList],
    {
      func: getFeedList,
    },
    {
      onError,
      enabled,
    }
  );
};

export default useGetFeedList;
