import classes from './CreatePostBox.component.module.scss';

import React from 'react';
import Paper from '@lobox/uikit/Paper';
import Divider from '@lobox/uikit/Divider';
import FilePicker from '@lobox/uikit/FilePicker';
import Flex from '@lobox/uikit/Flex/index';
import { useGlobalDispatch, useTranslation } from '@lobox/utils';

import Header from './CreatePostBox.header';
import Action from './CreatePostBox.action';

const CreatePostBox: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();

  const openCreatePostModal = (currentTab: string, extraData: any = {}) => {
    dispatch({
      type: 'SET_CREATE_POST_MODAL',
      payload: {
        isOpenModal: true,
        currentTab,
        ...extraData,
      },
    });
  };

  const handleFilePicker = (files: any[]) => {
    openCreatePostModal('media', {
      selectedMedia: files,
      closeModalOnBack: true,
    });
  };

  function openHighlightModal() {
    openCreatePostModal('highlight');
  }

  function openCheckIn() {
    openCreatePostModal('checkin');
  }

  return (
    <Paper
      className={classes.customPaper}
      contentClassName={classes.createPostContainer}
      noHover
      direction="column"
    >
      <Header />
      <Flex className={classes.dividerContainer}>
        <Divider className={classes.divider} />
      </Flex>
      <Flex className={classes.actions}>
        <FilePicker
          type="media"
          isMulti
          onFilePicked={handleFilePicker}
          buttonComponent={
            <Action
              label={`${t('photo')} / ${t('video')}`}
              icon="photo-video"
              iconColor="error"
            />
          }
        />
        <Action
          label={t('highlight')}
          onClick={openHighlightModal}
          icon="trophy-alt"
          iconColor="brand"
        />
        <Action
          label={t('checkin')}
          onClick={openCheckIn}
          icon="map-marker-plus"
          iconColor="success"
        />
      </Flex>
    </Paper>
  );
};

export default CreatePostBox;
