import React from 'react';
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual';
import debounce from 'lodash/debounce';
import Flex from '../Flex';

export type MeaSureElement = (node: Element | null, index: number) => void;

export interface ListItemProps {
  virtualizer: Virtualizer<Element, Element>;
  virtualRow: VirtualItem;
  children: React.ReactNode;
}

const InfiniteListItem = ({
  virtualizer,
  virtualRow,
  children,
}: ListItemProps): JSX.Element => {
  return (
    <Flex
      key={virtualRow.key}
      data-index={virtualRow.index}
      ref={virtualizer.measureElement}
    >
      {children}
    </Flex>
  );
};

export default InfiniteListItem;
