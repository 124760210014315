import React, { cloneElement, useRef, forwardRef } from 'react';
import type { ChangeEvent } from 'react';
import Flex from '../Flex';
import classes from './index.module.scss';

const pickTypes = {
  // _image: 'image/*',
  image: 'image/*,!image/landing,!image/gif',
  video: 'video/mp4,video/x-m4v,video/*',
  // _media: 'image/*,video/mp4,video/x-m4v,video/*',
  media: 'image/x-png,image/gif,image/jpeg,video/mp4,video/x-m4v,video/*',
  resume: '.doc, .docx,.txt,.pdf',
  messageInput: 'image/*,!image/landing,!image/gif,.doc, .docx,.txt,.pdf,.mp3',
};

interface FilePickerProps {
  buttonComponent?: React.ReactElement;
  onFilePicked: (file: File | File[]) => any;
  type?: keyof typeof pickTypes;
  isMulti?: boolean;
  disabled?: boolean;
}

const FilePicker = (
  {
    buttonComponent = <></>,
    onFilePicked,
    type = 'image',
    isMulti,
    disabled = false,
  }: FilePickerProps,
  ref: any
) => {
  const inputRef = useRef<{ value: string; click: Function }>(null);
  const defaultType = pickTypes[type] || pickTypes.image;

  const handlePickFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e?.target?.files;

    if (files?.length) {
      onFilePicked(isMulti ? Array.from(files) : files?.[0]);
    }

    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const Button = cloneElement(buttonComponent, {
    onClick: () => {
      inputRef?.current?.click();
    },
  });

  return (
    <>
      <Flex as="label" className={classes.imagePickerInput} ref={ref}>
        <input
          // @ts-ignore
          ref={inputRef}
          type="file"
          accept={defaultType}
          className={classes.imagePickerInput}
          onChange={handlePickFile}
          multiple={isMulti}
          disabled={disabled}
        />
      </Flex>
      {Button}
    </>
  );
};

export default forwardRef(FilePicker);
