export const initialState = {
  isOpenHashtag: false,
  selectedHashtag: undefined,
  highlights: [],
};

type ActionType =
  | {
      type: 'OPEN_TOP_BAR_HASHTAG';
      payload?: any;
    }
  | {
      type: 'CLOSE_TOP_BAR_HASHTAG';
      payload?: any;
    };

type StateType = typeof initialState;

export function homeReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'OPEN_TOP_BAR_HASHTAG':
      return {
        ...state,
        isOpenHashtag: true,
        selectedHashtag: action.payload,
      };
    case 'CLOSE_TOP_BAR_HASHTAG':
      return {
        ...state,
        isOpenHashtag: false,
        selectedHashtag: undefined,
      };
    default: {
      throw new Error(`Unsupported action type at Home Reducer`);
    }
  }
}
