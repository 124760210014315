import classes from './index.module.scss';

import React from 'react';
import cnj from '../utils/cnj';
import blender from '../utils/blender';
import Flex from '../Flex';
import { makeDynamicStyles } from '../utils/makeDynamicStyles';

interface ProgressBarProps {
  steps?: number;
  value: number;
  progressName?: string;
  className?: string;
  progressClassName?: string;
}

const calcPercentage = (value: number, total: number): number =>
  Math.ceil((value / total) * 100);

const ProgressBar: React.FC<ProgressBarProps> = ({
  steps: s,
  value: v,
  progressName = `${Math.random()}`,
  className,
  progressClassName,
}) => {
  const isStepsPassed = typeof s !== 'undefined';
  const steps: number = isStepsPassed ? s : 100;
  const value = v > steps ? steps : v;
  const dividerList = isStepsPassed ? Array(steps - 1).fill(0) : [];
  const startColor = '#83B4FF';
  const endColor = '#5384EE';

  return (
    <Flex
      className={cnj(
        classes.progressRoot,
        isStepsPassed && classes.isStepsPassedHeight,
        className
      )}
    >
      {dividerList.map((_, i) => {
        const index = i + 1;

        return (
          <Flex
            key={`${progressName}_${index}`}
            className={cnj(classes.divider, index === value && classes.hide)}
            style={{ left: `${index * (100 / steps)}%` }}
          />
        );
      })}
      <Flex
        className={cnj(classes.progressValue, progressClassName)}
        {...makeDynamicStyles({
          width: `${calcPercentage(value, steps)}%`,
          background: isStepsPassed
            ? `linear-gradient(to right, ${startColor}, ${blender(
                startColor,
                endColor,
                calcPercentage(value, steps)
              )})`
            : endColor,
        })}
      />
    </Flex>
  );
};

export default ProgressBar;
