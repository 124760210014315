import classes from './InfiniteList.module.scss';

import React, { useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import Flex from '../Flex';
import InfiniteListItem from './InfiniteListItem';

const getItemKeyPlaceholder = (index: number) => index.toString();

export interface InfiniteListProps {
  emptyPlaceholder?: React.ReactNode;
  initialLoadingSkeleton?: React.ReactNode;
  nextPageLoadingSkeleton?: React.ReactNode;
  topListItem?: React.ReactNode;
  isLoadingInitially: boolean;
  itemsCount: number;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  ListItem: React.FC<{ index: number }>;
  listItemMaxEstimatedHeight: number;
  overscan?: number;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, any>>;
  getItemKey?: (index: number) => string;
}

const InfiniteList = ({
  emptyPlaceholder,
  initialLoadingSkeleton,
  nextPageLoadingSkeleton,
  topListItem,
  isLoadingInitially,
  itemsCount,
  hasNextPage,
  isFetchingNextPage,
  overscan,
  ListItem,
  listItemMaxEstimatedHeight,
  fetchNextPage,
  getItemKey = getItemKeyPlaceholder,
}: InfiniteListProps): JSX.Element => {
  const parentRef = useRef(null);
  const isFetching = useRef(false);

  const virtualizer = useVirtualizer({
    count: hasNextPage ? itemsCount + 1 : itemsCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => listItemMaxEstimatedHeight,
    getItemKey,
    overscan,
  });

  const items = virtualizer.getVirtualItems();

  React.useEffect(() => {
    if (isFetching.current && !isFetchingNextPage) {
      isFetching.current = false;
    }
  }, [isFetchingNextPage]);

  React.useEffect(() => {
    const [lastItem] = [...items].reverse();

    if (!lastItem) return;

    if (
      lastItem.index >= itemsCount - 1 &&
      hasNextPage &&
      !isFetching.current
    ) {
      isFetching.current = true;
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, itemsCount, items]);

  return (
    <Flex ref={parentRef} className={classes.outerContainer}>
      {topListItem}
      <Flex
        className={classes.innerContainer}
        style={{
          height: virtualizer.getTotalSize(),
        }}
      >
        {isLoadingInitially && initialLoadingSkeleton}
        {!isLoadingInitially && !itemsCount && emptyPlaceholder}
        <Flex
          className={classes.scroller}
          style={{
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {items.map((virtualRow) => {
            return (
              <InfiniteListItem
                key={virtualRow.key}
                virtualizer={virtualizer}
                virtualRow={virtualRow}
              >
                {virtualRow.index > itemsCount - 1 && hasNextPage ? (
                  nextPageLoadingSkeleton
                ) : (
                  <ListItem index={virtualRow.index} />
                )}
              </InfiniteListItem>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(InfiniteList);
