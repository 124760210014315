import classes from './CreatePostBox.action.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';

interface CreatePostBoxActionProps {
  label: string;
  icon: string;
  iconColor: any;

  [key: string]: any;
}

const CreatePostBoxAction: React.FC<CreatePostBoxActionProps> = ({
  label,
  icon,
  iconColor,
  ...rest
}) => {
  return (
    <Button
      schema="ghost"
      className={classes.actionBtn}
      label={label}
      labelFont="bold"
      labelColor="fifthText"
      leftIcon={icon}
      leftColor={iconColor}
      leftIconClassName={classes.icon}
      labelClassName={classes.label}
      {...rest}
    />
  );
};

export default CreatePostBoxAction;
