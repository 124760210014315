import classes from './CreatePostProgressBar.component.module.scss';

import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Paper from '@lobox/uikit/Paper';
import ProgressBar from '@lobox/uikit/ProgressBar';
import cnj from '@lobox/uikit/utils/cnj';
import {
  QueryKeys,
  useGlobalState,
  useGlobalDispatch,
  event,
} from '@lobox/utils';
import eventKeys from '@shared/constants/event-keys';
import sum from 'lodash/sum';

type Position = { x: number; y: number };
type Ratio = '0:0' | '1:1' | '4:5' | '16:9';
type Size = { width: number; height: number };
type ReadyToUpload = {
  file: Blob;
  original: {
    type: string;
    file: File;
  };
  position: Position;
  ratio: Ratio;
  zoom: number;
  fileSize: string;
  duration: number;
  size: Size;
  realSize: Size;
  backgroundColor: string;
  secondaryLink: string;
};
type ReadyToPostFeed = {
  postIt?: boolean;
  isEditMode?: boolean;
  updatePostId?: number;
  values?: any;
  richTextInput?: string;
  isVisibleMapView?: boolean;
  selectedHighlight?: any;
  selectedLocation?: any;
  selectedUsers?: any;
  attachment?: any;
  pathname?: string;
  readyToUploadFiles?: ReadyToUpload[];
  files?: any;
};
interface Props {
  paperClassName?: string;
}

const CreatePostProgressBar = ({ paperClassName }: Props): JSX.Element => {
  const queryClient = useQueryClient();
  const createPostProgress = useGlobalState('createPostProgress');
  const { isOpenModal } = useGlobalState('createPostModal');
  const createdPostData = useGlobalState('createdPostData');
  const appDispatch = useGlobalDispatch();

  const readyToPostFeed: ReadyToPostFeed =
    queryClient.getQueryData([
      QueryKeys.readyToPostFeed,
      'READY_TO_POST_FEED',
    ]) || {};

  const feedSubmitted = Boolean(readyToPostFeed?.postIt && !isOpenModal);
  const postUploadProgressIndex =
    readyToPostFeed?.readyToUploadFiles?.length || 0;
  const progress = sum(createPostProgress) / (postUploadProgressIndex + 1); // +1 for post data

  React.useEffect(() => {
    if (!createdPostData || !createdPostData?.sender) {
      return;
    }

    if (readyToPostFeed?.isEditMode) {
      event.trigger(eventKeys.updatePost, createdPostData);
    } else {
      event.trigger(eventKeys.submitPost, createdPostData);
      if (readyToPostFeed?.attachment) {
        event.trigger(eventKeys.scrollToTopFeedList);
      }
    }
    appDispatch({
      type: 'RESET_CREATED_POST_DATA',
    });
  }, [createdPostData]);

  if (!feedSubmitted) {
    return <></>;
  }

  return (
    <Paper
      className={cnj(classes.customPaper, paperClassName)}
      contentClassName={classes.createPostContainer}
      direction="column"
      noHover
    >
      <ProgressBar
        progressClassName={classes.progressClassName}
        className={classes.progressbar}
        value={progress}
      />
    </Paper>
  );
};

export default CreatePostProgressBar;
